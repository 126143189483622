import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { HomePage } from '../../models/home.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';
import { LanguageQuery } from '../../state/language/language.query';

@UntilDestroy()
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
    private languageQuery: LanguageQuery,
  ) { }

  private home: HomePage;

  public status: string;
  public title: string;
  public markdownContent: string;

  public onReadyMarkdownContent(): void {
    // console.log('home.onReadyMarkdownContent');
    if (this.home) {
      // console.log('home.fireManualMyAppReadyEvent');
      this.ims.fireManualMyAppReadyEvent();
    }
  }

  public async ngOnInit(): Promise<void> {

    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(async language => {

      const home = await this.content.getHome();
      this.home = home;

      this.title = home.title;
      this.status = home.status;
      this.markdownContent = home.content;

      console.log('home', home);
    })

  }

}
