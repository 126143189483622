import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Lang, Language } from '../../models/language.model';

export interface LanguageState extends Language {
}

export function createInitialState(): LanguageState {
  return {
    key: Lang.en
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'language' })
export class LanguageStore extends Store<LanguageState> {

  constructor() {
    super(createInitialState());
  }

}
