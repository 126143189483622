<div class="home-page-wrapper prose lg:prose-lg max-w-none prose-blue" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="-mt-10 -mb-4 border-b border-gray-200">
    <h2>{{ title }}</h2>
  </div>

  <div class="flex flex-wrap overflow-hidden pb-2">

    <div class="w-full overflow-hidden md:w-2/3 md:pr-4">
      <div class="content prose lg:prose-lg" >
        <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>
      </div>
    </div>

    <div class="w-full overflow-hidden flex flex-row justify-center md:mt-10 px-4 border md:border-0 md:border-l border-gray-200 md:w-1/3">
      <img class="max-h-48 max-w-xs" src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-icon.png">
    </div>

  </div>

</div>

