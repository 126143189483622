import { Injectable } from '@angular/core';
import { LanguageKey } from '../../models/language.model';
import { LanguageStore } from './language.store';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  constructor(
    private languageStore: LanguageStore,
  ) {
  }

  set current(key: LanguageKey) {
    this.languageStore.update({
      key
    })
  }

}
