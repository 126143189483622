<div class="footer-wrapper bg-gray-50 border-t border-b">

  <footer class="flex flex-col items-center justify-content text-center p-8 pt-16 pb-24">
    <div class="font-semibold" [innerHTML]="firstLine"></div>
    <div class="flex items-center justify-content">
      <!-- <img src="https://ik.imagekit.io/scanshop/scanshop-static-web-starter/eu-flag.svg" alt="" class="w-20 h-15 m-8 mt-4"> -->
      <a href="https://s.ss.hr" target="_blank" >
        <img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title-white.svg" class="max-h-24 mt-0 max-w-full" >
      </a>
    </div>
    <div class="w-full lg:w-center text-center">
      All right reserved © Copyright 2021.<br>
      <a href="https://s.ss.hr" target="_blank" >
        <img src="https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title-white.svg" class="max-h-8 mt-4 max-w-full inline-block" >
      </a>
    </div>
    <div class="font-light text-xs mt-4" [innerHTML]="lastLine"></div>

  </footer>

</div>
