import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { ContactPage } from '../../models/contact.page.model';
import { PagesContentService } from '../../services/pages/pages-content.service';
import { LanguageQuery } from '../../state/language/language.query';

@UntilDestroy()
@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
    private content: PagesContentService,
    private languageQuery: LanguageQuery,
  ) { }

  private contact: ContactPage;

  public status: string;
  public title: string;
  public markdownContent: string;
  public markdownContentLinks: string;

  private isMarkdownContentReadyCounter = 0;

  public onReadyMarkdownContent(): void {
    console.log('contact.onReadyMarkdownContent');
    if (this.contact) {
      this.isMarkdownContentReadyCounter += 1;
      // Fire that app is read when all markdown fields are rendered
      if (this.isMarkdownContentReadyCounter >= 1) {
        console.log('contact.fireManualMyAppReadyEvent');
        this.ims.fireManualMyAppReadyEvent();
      }
    }
  }

  public async ngOnInit(): Promise<void> {

    this.languageQuery.select().pipe(untilDestroyed(this)).subscribe(async language => {

      const contact = await this.content.getContact();
      this.contact = contact;

      this.title = contact.title;
      this.status = contact.status;
      this.markdownContent = contact.content;
      this.markdownContentLinks = contact.links;

      console.log('contact', contact);

    })


  }

}
