import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Globals } from '../../models/globals.model';

export interface GlobalsState extends Globals {
}

export function createInitialState(): GlobalsState {
  return {
    en: {
      footer: {
        firstLine: '',
        lastLine: ''
      }
    },
    hr: {
      footer: {
        firstLine: '',
        lastLine: ''
      }
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'globals' })
export class GlobalsStore extends Store<GlobalsState> {

  constructor() {
    super(createInitialState());
  }

}
