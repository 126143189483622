import { AfterViewInit, Component } from '@angular/core';
import { HelpersService } from './services/helpers/helpers.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalsService } from './state/globals/globals.service';


@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  public constructor(
    private helpers: HelpersService,
    private router: Router,
    private globals: GlobalsService, // Required to reference here to call internal constructor on app start
  ) {

    this.init();
  }

  ngAfterViewInit(): void {
    this.hideSplashScreen()
    this.preloadModules()
  }

  /**
   * Init component
   */
  private async init(): Promise<void> {
  }

  /**
   * Default hide splash screen after constant time, but every page should manually hide splash screen before.
   * This is just a protection to avoid application stacked in the state with the splash screen if some component/page do not hide it manually.
   */
  private hideSplashScreen(): void {
    setTimeout(() => {
      this.helpers.hideSplashScreen();
    }, 1000);
  }

  /**
   * Some modules are set to be Lazy at app-routing.module.ts.
   * This is nice from faster loading of Home screen but it has negative UX at first Router navigation to these modules.
   * Best combination is to keep then Lazy at Routing module but to load them Async in background when Home page is available.
   */
  private async preloadModules(): Promise<void> {
    setTimeout(() => {
      import('./pages/blog-page/blog.module').then(m => m.BlogModule)
    }, 0)
  }
}
