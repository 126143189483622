import { Injectable } from '@angular/core';
import { FLContent } from 'ng-flamelink';
import { from } from 'rxjs';
import { AboutPage } from '../../models/about.page.model';
import { BlogPage } from '../../models/blog.page.model';
import { ContactPage } from '../../models/contact.page.model';
import { HomePage } from '../../models/home.page.model';
import { Page } from '../../models/page.model';
import { LanguageQuery } from '../../state/language/language.query';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class PagesContentService {

  constructor(
    private content: FLContent,
    private helpers: HelpersService,
    private languageQuery: LanguageQuery
  ) { }

  public async getHome(): Promise<HomePage> {

    const home = (await this.getPage('home')) as HomePage;
    return home;
  }

  public async getAbout(): Promise<AboutPage> {

    const about = (await this.getPage('about', ['numbers', 'area'])) as AboutPage;
    return about;
  }

  public async getBlog(): Promise<BlogPage> {

    const blog = (await this.getPage('blog')) as BlogPage;
    return blog;
  }

  public async getContact(): Promise<ContactPage> {

    const contact = (await this.getPage('contact', ['links'])) as ContactPage;
    return contact;
  }

  /**
   * Get page data by key
   * @param key is page identificator
   */
   private async getPage(key: string, additionalContentFields: string[] = []): Promise<Page> {
    const page$ = this.helpers.safeUseScullyTransferState(`page_${key}`, from(this.getPageDocument(key)));

    return new Promise((resolve) => {
      const sub = page$.subscribe((page: Page) => {
        console.log('getPage', key, page);

        // Get current language
        const language = this.languageQuery.getValue()

        // NOTE: without filtering Flamelink document
        // BAD: images are not poxied
        // GOOD: other properties can be access from the outside
        // return page;

        // Try to get specific translation of some content
        const pageTranslation: Page = page[language?.key]

        // Ifr translation is not available fallback to default (this is backward compatible behaviour for one language site)
        const title = pageTranslation?.title || page.title
        const content = pageTranslation?.content || page.content

        // NOTE: with filtering & transforming data from Flamelink document
        const data = {
          status: (page as any)?._fl_meta_?.status,
          title,
          content: this.helpers.proxyImages(content),
        };

        // about page has fields: numbers and area
        additionalContentFields.forEach(contentField => {
          data[contentField] = this.helpers.proxyImages( pageTranslation && pageTranslation[contentField] || page[contentField]);
        });

        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
          this.helpers.hideSplashScreen();
        });

        resolve(data);
      });
    });
  }

  /**
   * Get page document from Flamelink
   * @param key is schema key
   */
  private getPageDocument<T>(key: string): Promise<T> {
    return this.content.get({
      schemaKey: key
    });
  }

}
