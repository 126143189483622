import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sss-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public navbar = {
    titleImage: 'https://ik.imagekit.io/scanshop/scanshop-static-web-starter/scanshop-static-web-starter-logo.svg',
    title: `ScanShop SSS`,
    titleLink: '/',
    activeLink: '/',
    mainImage: 'https://ik.imagekit.io/scanshop/scanshop-landing-elements/scanshop-logo-blue-with-title-white.svg',
  }

  constructor() {

  }

  ngOnInit(): void {
  }

}
