import { Injectable } from '@angular/core';
import { FLContent } from 'ng-flamelink';
import { from, Observable } from 'rxjs';
import { GlobalTranslation } from '../../models/globals.model';
import { HelpersService } from '../../services/helpers/helpers.service';
import { LanguageQuery } from '../language/language.query';
import { GlobalsStore } from './globals.store';

@Injectable({ providedIn: 'root' })
export class GlobalsService {

  constructor(
    private globalsStore: GlobalsStore,
    private content: FLContent,
    private helpers: HelpersService,
    private languageQuery: LanguageQuery,
  ) {
    this.init()
  }

  get currentTranslation$() {
    return new Observable(subscriber => {
      this.languageQuery.select().subscribe(language => {
        const currentTranslation: GlobalTranslation = this.globalsStore.getValue()[language.key]
        subscriber.next(currentTranslation)
      })
    })
  }

  private async init() {

    const globals$ = this.helpers.safeUseScullyTransferState(`globals`, from(this.getGlobalsDocument()));

    globals$.subscribe((globals: any) => {
      // console.log('globals', globals)
      this.globalsStore.update({
        en: {
          footer: {
            firstLine: globals?.en?.footer?.firstLine,
            lastLine: globals?.en?.footer?.lastLine,
          }
        },
        hr: {
          footer: {
            firstLine: globals?.hr?.footer?.firstLine,
            lastLine: globals?.hr?.footer?.lastLine,
          }
        }
      })
    })

  }

  /**
   * Get globals document from Flamelink
   * @param key is schema key
   */
   private getGlobalsDocument<T>(): Promise<T> {
    return this.content.get({
      schemaKey: 'globals'
    });
  }


}
