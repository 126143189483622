import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GlobalTranslation } from '../../models/globals.model';
import { GlobalsQuery } from '../../state/globals/globals.query';
import { GlobalsService } from '../../state/globals/globals.service';

@UntilDestroy()
@Component({
  selector: 'sss-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  firstLine: string
  lastLine: string

  constructor(
    private globalsService: GlobalsService
  ) { }

  ngOnInit(): void {

    this.globalsService.currentTranslation$.pipe(untilDestroyed(this)).subscribe((globalTranslation: GlobalTranslation) => {
      console.log('footer.globalTranslation', globalTranslation)
      this.firstLine = globalTranslation.footer.firstLine
      this.lastLine = globalTranslation.footer.lastLine
    })

  }

}
