import { Injectable } from '@angular/core';
import { isScullyGenerated, isScullyRunning, TransferStateService } from '@scullyio/ng-lib';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const PORT = parseInt(location.port, 10);  // 4200
const HOSTNAME = location.hostname;        // 'localhost'

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private transferState: TransferStateService,
  ) { }

  public get isDynamicApp(): boolean {

    const dynamicHosts = environment.dynamic.hosts;
    if (dynamicHosts.includes(HOSTNAME)) {
      return true;
    }

    return !this.isStaticApp;
  }

  public get isStaticApp(): boolean {

    const staticHosts = environment.static.hosts;
    if (staticHosts.includes(HOSTNAME)) {
      return true;
    }

    // NOTE: 1668 is Scully testing port at localhost
    // NOTE: everything deployed to the Netlify should be static content
    if (PORT === 1668 || HOSTNAME.endsWith('.netlify.app')) {
      return true;
    }

    // PORT 4200, PORT 1864, HOSTNAME endsWith .web.app (at Firebase Hosting)
    return false;
  }

  // safely handles circular references
  /**
   *
   * @param obj
   * @param indent
   */
  public safeStringify = (obj, indent = 2) => {
    // Handle missing content
    if (obj === undefined) {
      return '{}';
    }
    let cache = [];
    const retVal = JSON.stringify(
      obj,
      (key, value) =>
        typeof value === 'object' && value !== null
          ? cache.includes(value)
            ? undefined // Duplicate reference found, discard key
            : cache.push(value) && value // Store value in our collection
          : value,
      indent
    );
    cache = null;
    return retVal;
  }

  public isIterable(obj): boolean {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  /**
   * Remove any circular reference from the object
   * @param obj is any object
   */
  public removeCircularReferences(obj): any {
    return JSON.parse(this.safeStringify(obj));
  }

  /**
   * Flamelink object has some huge and not required properties which are not relevant with data
   * @param flamelinkObj is array of Flamelink documents OR single Flamelink document
   */
  public cleanupFlamelinkContent(flamelinkObj: any[] | any): any[] | any {
    // console.log('cleanupFlamelinkContent.flamelinkObj', flamelinkObj);

    if (this.isIterable(flamelinkObj)) {
      // isArray
      for (const obj of flamelinkObj) {
        if (obj?._fl_meta_) {
          delete obj?._fl_meta_?.schemaRef;
          obj.meta = obj?._fl_meta_;
          delete obj?._fl_meta_;
        }
      }
    } else {
      // isObject
      // tslint:disable-next-line: forin
      for (const key in flamelinkObj) {
        if (flamelinkObj[key]?._fl_meta_) {
          delete flamelinkObj[key]?._fl_meta_?.schemaRef;
          flamelinkObj[key].meta = flamelinkObj[key]?._fl_meta_;
          delete flamelinkObj[key]?._fl_meta_;
        }
      }
      if (flamelinkObj?._fl_meta_) {
        delete flamelinkObj?._fl_meta_?.schemaRef;
        flamelinkObj.meta = flamelinkObj?._fl_meta_;
        delete flamelinkObj?._fl_meta_;
      }
    }
    return flamelinkObj;
  }

  /**
   * Wraps an observable into scully's transfer state. If data for the provided `name` is
   * available in the state, it gets returned. Otherwise, the `originalState` observable will
   * be returned.
   *
   * On subsequent calls, the data in the state will always be returned. The `originalState` will
   * be returned only once.
   *
   * This is a convenience method which does not require you to use `getState`/`setState` manually.
   *
   * @param name state key
   * @param originalState an observable which yields the desired data
   */
  public safeUseScullyTransferState<T>(name: string, originalState: Observable<T>): Observable<T> {
    if (isScullyGenerated()) {
      return this.transferState.getState(name);
    }

    return originalState.pipe(tap((state) => {
      // NOTE: 3. store data to offline cache
      this.transferState.setState(name,
          // NOTE: 2. remove huge "trash" internal objects
          this.cleanupFlamelinkContent(
            // NOTE: 1. remove circular references
            this.removeCircularReferences(state)
          )
      );
      // NOTE: this should not be part of this service because component could depend on multiple external API(s)
      // and when all of them are finished then ready event should be fired, not before (when this call is finished)!
      // NOTE: this is the side-effect fix
      // if (isScullyRunning()) {
      //   this.ims.fireManualMyAppReadyEvent();
      // }
    }));
  }

  /**
   * Proxy all images through Imagekit.io
   * @param content is any string (Markdown or HTML) content which can have image URL(s)
   */
  public proxyImages(content: string): string {

    const proxyEndpoint = 'https://ik.imagekit.io/scanshop/tr:q-95';
    const originBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/scanshop-static-web-starter.appspot.com/o';

    return content?.split(originBaseUrl)?.join(proxyEndpoint);
  }

  /**
   * Hide global preloading splash screen from index.html
   */
  public hideSplashScreen(): void {
    const splashScreenElement = document.getElementById('splash-screen');
    // Do not remove during Scully building keep it in static build
    if (isScullyGenerated() || !isScullyRunning()) {
      splashScreenElement?.remove();
    }
  }
}
