/**
 * This type is definition of available languages on the website.
 * NOTE(stepanic): do not change this values because Flamelink CMS schema depends on it.
 * These values should always be in the scope of language ISO 639-1 codes (https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes).
 * Add only languages for which you have a translated content to avoid performance issues during fetching date with Flamelink SDK.
 */
export enum Lang {
  en = 'en',
  hr = 'hr'
}

export type LanguageKey = Lang.en | Lang.hr;

export interface Language {
  key: LanguageKey
  title?: string
}
