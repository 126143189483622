<div class="layout-wrapper">

  <div class="flex flex-wrap overflow-hidden">

    <!-- HEADER -->
    <div class="w-full overflow-hidden lg:fixed z-30 bg-white">
      <sss-header>
        <sss-navbar [title]="navbar.title"
                [titleImage]="navbar.titleImage"
                [titleLink]="navbar.titleLink"
                [activeLink]="navbar.activeLink"
                [mainImage]="navbar.mainImage">
        </sss-navbar>
      </sss-header>
    </div>

    <!-- MAIN -->
    <div class="w-full overflow-hidden lg:pt-36 mt-4">
      <main class="container mx-auto py-4">
        <section>
          <ng-content></ng-content>
        </section>
      </main>
    </div>

    <!-- FOOTER -->
    <div class="w-full overflow-hidden">
      <sss-footer></sss-footer>
    </div>

  </div>


</div>
