// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCDaYdrLYMIelusWW4Tkpnakv54G-CEyx8',
    authDomain: 'scanshop-static-web-starter.firebaseapp.com',
    projectId: 'scanshop-static-web-starter',
    storageBucket: 'scanshop-static-web-starter.appspot.com',
    messagingSenderId: '91294650535',
    appId: '1:91294650535:web:6c951da2858ee9cd114b82',
    measurementId: 'G-YBS08TGCL7'
  },
  // NOTE: implicit STATIC app are at port 1668 and hostname endsWith .netlify.app
  static: {
    hosts: [
      'scanshop-static-web-starter.ss.hr' // @Netlify
    ]
  },
  // NOTE: implicit DYNAMIC app is at ports 4200 and 1864 and hostname endsWith .web.app
  dynamic: {
    hosts: [
      // NOTE: explicit hostname endsWith .web.app is redundant but only defined as placeholder to be as example
      'scanshop-static-web-starter.web.app' // @Firebase Hosting
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
